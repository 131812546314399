.App {
  text-align: center;
  max-width: 920px;
  margin: 0 auto;
}
@media (min-width: 65em) {
    .App {
      max-width: 1000px;
    }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.headershadow{
  position: absolute;
  z-index: -20;
  margin-left: -120px;
  margin-top: -140px;
  
}

.button {
  color: #FCFCFC;
  font-size: smaller;
  border-radius: 2px;
  background-image: linear-gradient(to bottom right, #4AAAFF, #4A56FF);
  border: none;
  height: 36px;
  width: 86px;
  border-radius: 5px;
}
a {
  text-decoration: none;
  color: white;
}
a:hover  {
   color: #5ebffc;
   transition: 0.5s ease-in-out;
}
.f-sm {
  font-size: x-small;
}
.f-l{
  font-size: large;
  font-weight: 400;
}
iframe {
  display: none;
}