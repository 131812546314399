@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Poppins:wght@100;500;900&display=swap');

:root {
  box-sizing: border-box;
  font-size: calc(1vw + 1em); 
}
@media (min-width: 48em) {
  :root {
    font-size: calc(1vw + 0.7em); 
  }
}
*, ::before, ::after {
  box-sizing: inherit;
}
body * + * {
  margin-top: 1.5em;
}
body {
  margin: 0;
  font-family: 'Bungee Inline', cursive;
  font-family: 'Poppins', sans-serif;
  background-color: #1b2025;
  color: #FCFCFC;
}
code {
  font-family: 'Bungee Inline', cursive;
  font-family: 'Poppins', sans-serif;
}
