.Why, h5 {
    text-align: left;
    padding-left: 12px;
    font-weight: 600;
    width: auto;
}
.whycontent.whycontent, h3 {
    padding-left: 12px;
    margin-bottom: 4px;
    margin-top: 32px;
}
@media (max-width: 47em) {
    .whycontent.whycontent {
        padding-left: 0px;
}
.container.container.container {
    padding-left: 5px !important;
}
}
.whycontent.whycontent > img {
    margin-top: 0;
}
.whycontent.whycontent > h5{
    margin-top: -0.4em;
}
.whylogo1, .whylogo2, .whylogo3, .whylogo4, .whylogo5, .whylogo6 {
    transform: scale(1.1);
    margin-left: 0px;
    padding: 0px;
    width: max-content;
    object-fit: center;

    display: table !important;
    margin: 0 auto !important;
}
.container.container {
    position: relative;
    border-left:7px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color:rgba(54, 68, 81, 0.99);
    padding-left: 20px;
}
.slider {
    z-index: 2;
    position: absolute;
    border-left:6px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color:rgba(8, 103, 193, 0.99);
    height: 40px;
    left: -7px;
    animation: straightLine 8.5s infinite linear;
}

.slidercover {
    position: absolute;
    height: 140px;
    width: 14px;
    z-index: 3;
    bottom: -70px;
    left: -10px;
    background-color: #1b2025;;
    color: #1b2025;;
    margin-top: 0.11px;
}

/* desktop view */
@media (min-width: 48em) {
    .whyflex {
      display: flex;
      column-gap: 2rem;
      /* justify-items: center; */
    }
    .whyflex__twist {
        display: flex;
        column-gap: 2rem;
    }
    .whyflex__twist > p {
        order: -1;
    }
    .whyimage {
        min-width: 300px;
    }
}
@media (min-width: 54em) {
    .whyflex {
      column-gap: 3rem;
    }
    .whyflex__twist {
        column-gap: 3rem;
    }
}


/* silder mobile */

@media (min-width: 40em) {
    .slidercover {
        height: 200px;
        bottom: -58px !important;
    }
    @keyframes straightLine {
        0% {
           transform: translate(1.6px);
        }
          100% {
            transform: translate(0, 1800px);
        }
    }
}

@keyframes straightLine {
    0% {
       transform: translate(1.6px);
    }
      100% {
        transform: translate(0, 2540px);
    }
}



/* Slider desktop */
@media (min-width: 50em) {
    @keyframes straightLine {
        0% {
           transform: translate(1.6px);
        }
          100% {
            transform: translate(0, 1560px);
        }
    }
}
@media (min-width: 58em) {
    @keyframes straightLine {
        0% {
           transform: translate(1.6px);
        }
          100% {
            transform: translate(0, 1530px);
        }
    }
}
@media (min-width: 65em) {
    @keyframes straightLine {
        0% {
           transform: translate(1.6px);
        }
          100% {
            transform: translate(0, 1705px);
        }
    }
}