.footer {
    display: flex;
    position: relative;
    justify-content: space-between;
    height: 280px;

}
@media (max-width: 40em) {
    .footer{
       background-color: #181d22;
    }
}
.footer h4{
    margin-top: 100px;
    margin-left: -36px;
}
.footer h5{
    margin-bottom: 50px;
    font-size: large;
    width: max-content;
    height: 18px;
}
.footerbutton {
    position: absolute;
    left: 12px;
    width: 100px;
    height: 38px;
    padding-top: 40px;
    font-size: medium;
    background-color: #4AAAFF;
    border-radius: 6px;
    padding-top: 3px;
    transition: all 1s;
    color: white;
    border: 0;
}
.footerbutton:hover {
    border: 1px solid #4AAAFF;
    color: #4AAAFF;
    background-color: rgb(26, 32, 38, .99)!important;
    transition: all 1s;
}
.footernav {
    list-style: none;
    z-index: 9;
    text-align: left;
    justify-content: center;
    margin: 0;
    padding-inline-start: 12px;
    border-radius: 8px;
    margin-left: 8px;
}
.foot2 {
    align-self: flex-end;
    
}
.footernav {
    margin-top: -24px;
}
.footernav li {
    margin-top: 0px;
    font-size: small;
}
.footerul li img {
    margin-right: 6px;
}
.quick {
    text-align: center;
    margin-left: -80px;
    margin-top: 0px;
    margin-bottom: 0px;
    height:22px;
}
@media (min-width: 40em) {
    .quick {
        height:12px;
    }
}
.copyright {
    margin-top: 0px;
}
@media (max-width: 40em) {
    .copyright {
       background-color: #181d22;
    }
    .quick {
        margin-left: -78px;
    }
}
@media (min-width: 40em) {
    .footernav li{
        display: inline;
        margin-right: 18px;
        
    }
}
.copyright > span {
    color: gray;
}
.footerbutton {
    margin-top: -30px;
}
@media (max-width: 40em) {
    .foot2{
        position: absolute;
        right: 12px;
        
    }
    .footerlogo {
        width: 220px;
        height: 220px;
    }
}