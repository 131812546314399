.header {
    padding-left: 24px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgb(26, 32, 38, .99);
    margin-top: 0em;
    border-radius: 8px;
}

.navBar {
    position: relative;
  }
  
.navBar button {
    position: fixed;
    left: 5px;
    top: 40px;
    z-index: 10;
    cursor: pointer;
}
  
.menuNav {
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: 0;
    background: #4AAAFF;
    left: 0;
    bottom: 0;
    height: 40vh;
    width: 100%;
    z-index: 9;
    display: none;
    text-align: center;
    justify-content: center;
    margin-top: 0;
    padding-inline-start: 0px;
    border-radius: 8px;
    transition: 2s ease-in-out;
    top: -100%; 
}
  
.menuNav.showMenu {
    display: block;
    transition: 2s ease-in-out;
    top: 0;  
}

.menuNav li:first-child {
  margin-top: 4rem;
}

.navBar button {
    background-color: transparent;
    border-width: 0;
    margin-top: -12px;
}

.header_logo {
  margin-left: 40px;
}

.navbutton {
  color:#4AAAFF !important;
  position: relative;
  margin-left: 75%;
  top: 8px;
  background-image: linear-gradient(to bottom, #FCFCFC, #FCFCFC) !important;
}
.navbutton > a {
  color:#4AAAFF !important;
}
@media ( min-width: 820px) {
  .navbutton {
    margin-left: 90%;
  }
}

.navlogo {
  margin-top: 34px;
}



/* desktop css stylesheet  */
@media (min-width: 48em) {
  .header {
    display: none;
  }
}

.header__desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -8px;
}
.menuNav__desktop1 {
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.menuNav__desktop1 > li {
  display: inline;
  margin-top: 0px;
  padding-left: 24px;
  margin-left: 8px;
  padding-right: 24px;
  margin-right: 8px;
  font-weight: 300;
  font-size: small;
}
.menuNav__desktop2 {
  list-style: none;
}
.menuNav__desktop2 > li {
  display: inline;
  margin-top: 16px;
}
.header__desktop__item2 {
  margin-top: 0px;
}
.header__desktop__item3{
  display: flex;
  align-content: center;
  margin-top: 2px;
  justify-content: space-evenly;
}
.header__desktop__item3 {
  color: #FCFCFC;
  margin-left: 18px;
  font-size: small;
  height: 34px;
  width: 100px;
  background-color: #4AAAFF;
  border-radius: 6px;
  padding-top: 8.5px;
  transition: all 1s;
  border: #4AAAFF;
  
}
.header__desktop__item3:hover {
  border: 1px solid #4AAAFF;
  color: #4AAAFF;
  background-color: rgb(26, 32, 38, .99);
  transition: all 1s;
}
.menuNav__desktop2 > li >img {
  margin-top: 6px;
  margin-left: 12px;
  width: 26px;
}
.header__desktop__item3 > button{
  width: 120px;
  height: 40px;
  margin-right: 12px;
}

@media (max-width: 48em) {
  .header__desktop {
    display: none;
}
}
.mobile__button {
  background-color: #4AAAFF;
  border-radius: 6px;
  font-size: medium;
  padding: 8px 14px;
  background-color: #4AAAFF;
  color: #FCFCFC;
  border: 0;
}
.mobile__button:hover {
  border: 1px solid #4AAAFF;
  color: #4AAAFF;
  background-color: rgb(26, 32, 38, .99);
  transition: all 1s;
}