.clients{
    z-index: 8 !important;
    margin-left: 20px;
}
.happycards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    row-gap: 22px;
    column-gap: -34px;
}
.happycard {
    position: relative;
    margin-top: auto;
}
.happyglowDot {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1a2026;
    box-shadow: inset 0px 0px 10px 2px #4AAAFF,
                    0px 0px 10px 2px #1c74c1;
    animation: pulse 1s alternate infinite;
    bottom: 56.2px;
    right: 16.2px;
}