@import url(https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Poppins:wght@100;500;900&display=swap);
:root {
  box-sizing: border-box;
  font-size: calc(1vw + 1em); 
}
@media (min-width: 48em) {
  :root {
    font-size: calc(1vw + 0.7em); 
  }
}
*, ::before, ::after {
  box-sizing: inherit;
}
body * + * {
  margin-top: 1.5em;
}
body {
  margin: 0;
  font-family: 'Bungee Inline', cursive;
  font-family: 'Poppins', sans-serif;
  background-color: #1b2025;
  color: #FCFCFC;
}
code {
  font-family: 'Bungee Inline', cursive;
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
  max-width: 920px;
  margin: 0 auto;
}
@media (min-width: 65em) {
    .App {
      max-width: 1000px;
    }
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.headershadow{
  position: absolute;
  z-index: -20;
  margin-left: -120px;
  margin-top: -140px;
  
}

.button {
  color: #FCFCFC;
  font-size: smaller;
  border-radius: 2px;
  background-image: linear-gradient(to bottom right, #4AAAFF, #4A56FF);
  border: none;
  height: 36px;
  width: 86px;
  border-radius: 5px;
}
a {
  text-decoration: none;
  color: white;
}
a:hover  {
   color: #5ebffc;
   transition: 0.5s ease-in-out;
}
.f-sm {
  font-size: x-small;
}
.f-l{
  font-size: large;
  font-weight: 400;
}
iframe {
  display: none;
}
.header {
    padding-left: 24px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgb(26, 32, 38, .99);
    margin-top: 0em;
    border-radius: 8px;
}

.navBar {
    position: relative;
  }
  
.navBar button {
    position: fixed;
    left: 5px;
    top: 40px;
    z-index: 10;
    cursor: pointer;
}
  
.menuNav {
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: 0;
    background: #4AAAFF;
    left: 0;
    bottom: 0;
    height: 40vh;
    width: 100%;
    z-index: 9;
    display: none;
    text-align: center;
    justify-content: center;
    margin-top: 0;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    border-radius: 8px;
    transition: 2s ease-in-out;
    top: -100%; 
}
  
.menuNav.showMenu {
    display: block;
    transition: 2s ease-in-out;
    top: 0;  
}

.menuNav li:first-child {
  margin-top: 4rem;
}

.navBar button {
    background-color: transparent;
    border-width: 0;
    margin-top: -12px;
}

.header_logo {
  margin-left: 40px;
}

.navbutton {
  color:#4AAAFF !important;
  position: relative;
  margin-left: 75%;
  top: 8px;
  background-image: linear-gradient(to bottom, #FCFCFC, #FCFCFC) !important;
}
.navbutton > a {
  color:#4AAAFF !important;
}
@media ( min-width: 820px) {
  .navbutton {
    margin-left: 90%;
  }
}

.navlogo {
  margin-top: 34px;
}



/* desktop css stylesheet  */
@media (min-width: 48em) {
  .header {
    display: none;
  }
}

.header__desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -8px;
}
.menuNav__desktop1 {
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.menuNav__desktop1 > li {
  display: inline;
  margin-top: 0px;
  padding-left: 24px;
  margin-left: 8px;
  padding-right: 24px;
  margin-right: 8px;
  font-weight: 300;
  font-size: small;
}
.menuNav__desktop2 {
  list-style: none;
}
.menuNav__desktop2 > li {
  display: inline;
  margin-top: 16px;
}
.header__desktop__item2 {
  margin-top: 0px;
}
.header__desktop__item3{
  display: flex;
  align-content: center;
  margin-top: 2px;
  justify-content: space-evenly;
}
.header__desktop__item3 {
  color: #FCFCFC;
  margin-left: 18px;
  font-size: small;
  height: 34px;
  width: 100px;
  background-color: #4AAAFF;
  border-radius: 6px;
  padding-top: 8.5px;
  transition: all 1s;
  border: #4AAAFF;
  
}
.header__desktop__item3:hover {
  border: 1px solid #4AAAFF;
  color: #4AAAFF;
  background-color: rgb(26, 32, 38, .99);
  transition: all 1s;
}
.menuNav__desktop2 > li >img {
  margin-top: 6px;
  margin-left: 12px;
  width: 26px;
}
.header__desktop__item3 > button{
  width: 120px;
  height: 40px;
  margin-right: 12px;
}

@media (max-width: 48em) {
  .header__desktop {
    display: none;
}
}
.mobile__button {
  background-color: #4AAAFF;
  border-radius: 6px;
  font-size: medium;
  padding: 8px 14px;
  background-color: #4AAAFF;
  color: #FCFCFC;
  border: 0;
}
.mobile__button:hover {
  border: 1px solid #4AAAFF;
  color: #4AAAFF;
  background-color: rgb(26, 32, 38, .99);
  transition: all 1s;
}
.aside {
    padding-top: 110px;
}
@media (max-width: 1500px) {
    .aside {
        padding-top: 110px;
    }
}

.aside__top {
    font-size: large;
    height: 48px !important;
    width: 170px !important;
    background-color: #4AAAFF;
    border-radius: 6px;
    border: 0;
    transition: all 1s;
    color: white;
}
.aside__top:hover {
    border: 1px solid #4AAAFF;
    color: #4AAAFF;
    background-color: rgb(26, 32, 38, .99);
    transition: all 1s;
}
.aside .asidebutton {
    margin-top: 40px;
    height: 50px;
    width: 156px;
    font-size: large;
}
.asidelogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

}
@media (min-width: 48em) {
    .aside {
        padding-top: 60px;
    }
    .asidelogo {
        width: 580px;
        height: 200;
    }
    .asidemenubar {
        display: none;
    }
}@media (orientation: portrait) {
    .aside {
        height: auto;
    }
}
.aside ul {
    list-style-type: none;
    margin-left: auto;
    margin-right: auto;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    margin-top: 40px;
}
.aside ul li {
    display: inline;
    margin-right: 6px;
}
.aside ul:first-child {
    margin-left: -6px;
}

/*  
    for the cards the parent element must have a position
    other than static for the abosolute value to work...
*/
.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 4% 5%;
    gap: 4% 5%;
    /* width: 900px; */
}
.card {
    position: relative;
    margin-top: auto;
}

.glowDot {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1a2026;
    box-shadow: inset 0px 0px 10px 2px #4AAAFF,
                    0px 0px 10px 2px #1c74c1;
    -webkit-animation: pulse 0.6s alternate infinite;
            animation: pulse 0.6s alternate infinite;
    bottom: 21px;
    right: 14px;
}

@-webkit-keyframes pulse {
    0% {
      background: #1c74c1;
      box-shadow: none;
    }
    100% {
      background: #1c74c1;
      box-shadow: inset 0px 0px 10px 2px #1c74c1
                        0px 0px 15px 2px #93c9f8;
    }
  }

@keyframes pulse {
    0% {
      background: #1c74c1;
      box-shadow: none;
    }
    100% {
      background: #1c74c1;
      box-shadow: inset 0px 0px 10px 2px #1c74c1
                        0px 0px 15px 2px #93c9f8;
    }
  }
/* what CSS stylesheet */
@media (min-width: 45em) {
  .what {
    display: flex;
    justify-items: normal;
    justify-content: space-between;
    margin-bottom: 100px;
  }
  .what__desktop2 {
    margin-left: 360px;
  }
}

.what, h5 {
  text-align: left;
  padding-left: 12px;
  height: 23px;
  width: 140px;
  font-weight: 500;
}
.what, p {
  font-size: 0.8em;
  padding-left: 16px !important;
  padding-right: 12px;
  text-align: left;
  font-weight: 300;
  line-height: 1.4rem;
  font-size: smaller;
  width: auto;
  height: auto;
}
.MNMA {
  color: #1c74c1;
}
.whatcards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 12px 12px;
  gap: 12px 12px;
}
.whatcard {
  position: relative;
  margin-top: auto;
}

.whatglowDot {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #1a2026;
    box-shadow: inset 0px 0px 10px 2px #4AAAFF,
                    0px 0px 10px 2px #1c74c1;
    -webkit-animation: pulse 0.6s alternate infinite;
            animation: pulse 0.6s alternate infinite;
    bottom: 18px;
    right: 8px;
}

@-webkit-keyframes pulse {
  0% {
    background: #1c74c1;
    box-shadow: none;
  }
  100% {
    background: #1c74c1;
    box-shadow: inset 0px 0px 10px 2px #1c74c1
                      0px 0px 15px 2px #93c9f8;
  }
}

@keyframes pulse {
  0% {
    background: #1c74c1;
    box-shadow: none;
  }
  100% {
    background: #1c74c1;
    box-shadow: inset 0px 0px 10px 2px #1c74c1
                      0px 0px 15px 2px #93c9f8;
  }
}

.whatimage {
  position: relative;
  height: 280px;
  margin-top: 3em;
}
.whatimage__telegram {
  position: absolute;
  bottom: 66px;
  right: 88px;
  width: 100px;
  -webkit-animation: telegram 1s alternate infinite;
          animation: telegram 1s alternate infinite; 
}
.whatimage__discord {
  position: absolute;
  right: 120px;
  width: 160px;
  -webkit-animation: discord 1s alternate infinite;
          animation: discord 1s alternate infinite;
}
.whatimage__twitter {
  position: absolute;
  right: 202px;
  top: -40px;
  width: 120px;
  -webkit-animation: twitter 1s alternate infinite;
          animation: twitter 1s alternate infinite;
}

@-webkit-keyframes discord {
  0% {
    -webkit-transform: scale(0.88);
            transform: scale(0.88);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes discord {
  0% {
    -webkit-transform: scale(0.88);
            transform: scale(0.88);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes telegram {
  0% {
    -webkit-transform: scale(0.88) translate(0px, 0px);
            transform: scale(0.88) translate(0px, 0px);
  }
  100% {
    -webkit-transform: scale(1) translate(10px, 16px);
            transform: scale(1) translate(10px, 16px);
  }
}
@keyframes telegram {
  0% {
    -webkit-transform: scale(0.88) translate(0px, 0px);
            transform: scale(0.88) translate(0px, 0px);
  }
  100% {
    -webkit-transform: scale(1) translate(10px, 16px);
            transform: scale(1) translate(10px, 16px);
  }
}
@-webkit-keyframes twitter {
  0% {
    -webkit-transform: scale(0.88) translate(10px, 10px);
            transform: scale(0.88) translate(10px, 10px);
  }
  100% {
    -webkit-transform: scale(1) translate(-6px, 0px);
            transform: scale(1) translate(-6px, 0px); 
  }
}
@keyframes twitter {
  0% {
    -webkit-transform: scale(0.88) translate(10px, 10px);
            transform: scale(0.88) translate(10px, 10px);
  }
  100% {
    -webkit-transform: scale(1) translate(-6px, 0px);
            transform: scale(1) translate(-6px, 0px); 
  }
}


/* About CSS stylesheet */
.about, h5 {
  text-align: left;
  padding-left: 4px;
  font-weight: 500;
}
.about, p {
  width: auto;
}
.aboutimage {
  position: relative;
  top: -24px;
  height: 340px;
  z-index: -12;
  margin-left: 12px;
}

@media (min-width: 45em) {
  .about {
    display: flex;
    justify-items: normal;
    justify-content: space-between;
    margin-bottom: 100px;
    align-items: center;
  }
  .what__desktop1 > h5 {
    margin-top: 120px;
  }
  .what__desktop2 {
    margin-left: 360px;
    margin-top: 90px;
  }
  .about__desktop2 {
    order: -1;
  }
  .abouticon {
    margin-right: 80px;
    -webkit-transform: scale(1.4);
            transform: scale(1.4)
  }
}

@media (min-width: 56em) {
  .abouticon {
    margin-right: 80px;
    -webkit-transform: scale(1.7);
            transform: scale(1.7)
  }
}

.Why, h5 {
    text-align: left;
    padding-left: 12px;
    font-weight: 600;
    width: auto;
}
.whycontent.whycontent, h3 {
    padding-left: 12px;
    margin-bottom: 4px;
    margin-top: 32px;
}
@media (max-width: 47em) {
    .whycontent.whycontent {
        padding-left: 0px;
}
.container.container.container {
    padding-left: 5px !important;
}
}
.whycontent.whycontent > img {
    margin-top: 0;
}
.whycontent.whycontent > h5{
    margin-top: -0.4em;
}
.whylogo1, .whylogo2, .whylogo3, .whylogo4, .whylogo5, .whylogo6 {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    margin-left: 0px;
    padding: 0px;
    width: -webkit-max-content;
    width: max-content;
    object-fit: center;

    display: table !important;
    margin: 0 auto !important;
}
.container.container {
    position: relative;
    border-left:7px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color:rgba(54, 68, 81, 0.99);
    padding-left: 20px;
}
.slider {
    z-index: 2;
    position: absolute;
    border-left:6px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color:rgba(8, 103, 193, 0.99);
    height: 40px;
    left: -7px;
    -webkit-animation: straightLine 8.5s infinite linear;
            animation: straightLine 8.5s infinite linear;
}

.slidercover {
    position: absolute;
    height: 140px;
    width: 14px;
    z-index: 3;
    bottom: -70px;
    left: -10px;
    background-color: #1b2025;;
    color: #1b2025;;
    margin-top: 0.11px;
}

/* desktop view */
@media (min-width: 48em) {
    .whyflex {
      display: flex;
      grid-column-gap: 2rem;
      -webkit-column-gap: 2rem;
              column-gap: 2rem;
      /* justify-items: center; */
    }
    .whyflex__twist {
        display: flex;
        grid-column-gap: 2rem;
        -webkit-column-gap: 2rem;
                column-gap: 2rem;
    }
    .whyflex__twist > p {
        order: -1;
    }
    .whyimage {
        min-width: 300px;
    }
}
@media (min-width: 54em) {
    .whyflex {
      grid-column-gap: 3rem;
      -webkit-column-gap: 3rem;
              column-gap: 3rem;
    }
    .whyflex__twist {
        grid-column-gap: 3rem;
        -webkit-column-gap: 3rem;
                column-gap: 3rem;
    }
}


/* silder mobile */

@media (min-width: 40em) {
    .slidercover {
        height: 200px;
        bottom: -58px !important;
    }
    @-webkit-keyframes straightLine {
        0% {
           -webkit-transform: translate(1.6px);
                   transform: translate(1.6px);
        }
          100% {
            -webkit-transform: translate(0, 1800px);
                    transform: translate(0, 1800px);
        }
    }
    @keyframes straightLine {
        0% {
           -webkit-transform: translate(1.6px);
                   transform: translate(1.6px);
        }
          100% {
            -webkit-transform: translate(0, 1800px);
                    transform: translate(0, 1800px);
        }
    }
}

@-webkit-keyframes straightLine {
    0% {
       -webkit-transform: translate(1.6px);
               transform: translate(1.6px);
    }
      100% {
        -webkit-transform: translate(0, 2540px);
                transform: translate(0, 2540px);
    }
}

@keyframes straightLine {
    0% {
       -webkit-transform: translate(1.6px);
               transform: translate(1.6px);
    }
      100% {
        -webkit-transform: translate(0, 2540px);
                transform: translate(0, 2540px);
    }
}



/* Slider desktop */
@media (min-width: 50em) {
    @-webkit-keyframes straightLine {
        0% {
           -webkit-transform: translate(1.6px);
                   transform: translate(1.6px);
        }
          100% {
            -webkit-transform: translate(0, 1560px);
                    transform: translate(0, 1560px);
        }
    }
    @keyframes straightLine {
        0% {
           -webkit-transform: translate(1.6px);
                   transform: translate(1.6px);
        }
          100% {
            -webkit-transform: translate(0, 1560px);
                    transform: translate(0, 1560px);
        }
    }
}
@media (min-width: 58em) {
    @-webkit-keyframes straightLine {
        0% {
           -webkit-transform: translate(1.6px);
                   transform: translate(1.6px);
        }
          100% {
            -webkit-transform: translate(0, 1530px);
                    transform: translate(0, 1530px);
        }
    }
    @keyframes straightLine {
        0% {
           -webkit-transform: translate(1.6px);
                   transform: translate(1.6px);
        }
          100% {
            -webkit-transform: translate(0, 1530px);
                    transform: translate(0, 1530px);
        }
    }
}
@media (min-width: 65em) {
    @-webkit-keyframes straightLine {
        0% {
           -webkit-transform: translate(1.6px);
                   transform: translate(1.6px);
        }
          100% {
            -webkit-transform: translate(0, 1705px);
                    transform: translate(0, 1705px);
        }
    }
    @keyframes straightLine {
        0% {
           -webkit-transform: translate(1.6px);
                   transform: translate(1.6px);
        }
          100% {
            -webkit-transform: translate(0, 1705px);
                    transform: translate(0, 1705px);
        }
    }
}
.clients{
    z-index: 8 !important;
    margin-left: 20px;
}
.happycards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    grid-row-gap: 22px;
    row-gap: 22px;
    grid-column-gap: -34px;
    -webkit-column-gap: -34px;
            column-gap: -34px;
}
.happycard {
    position: relative;
    margin-top: auto;
}
.happyglowDot {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1a2026;
    box-shadow: inset 0px 0px 10px 2px #4AAAFF,
                    0px 0px 10px 2px #1c74c1;
    -webkit-animation: pulse 1s alternate infinite;
            animation: pulse 1s alternate infinite;
    bottom: 56.2px;
    right: 16.2px;
}
.footer {
    display: flex;
    position: relative;
    justify-content: space-between;
    height: 280px;

}
@media (max-width: 40em) {
    .footer{
       background-color: #181d22;
    }
}
.footer h4{
    margin-top: 100px;
    margin-left: -36px;
}
.footer h5{
    margin-bottom: 50px;
    font-size: large;
    width: -webkit-max-content;
    width: max-content;
    height: 18px;
}
.footerbutton {
    position: absolute;
    left: 12px;
    width: 100px;
    height: 38px;
    padding-top: 40px;
    font-size: medium;
    background-color: #4AAAFF;
    border-radius: 6px;
    padding-top: 3px;
    transition: all 1s;
    color: white;
    border: 0;
}
.footerbutton:hover {
    border: 1px solid #4AAAFF;
    color: #4AAAFF;
    background-color: rgb(26, 32, 38, .99)!important;
    transition: all 1s;
}
.footernav {
    list-style: none;
    z-index: 9;
    text-align: left;
    justify-content: center;
    margin: 0;
    -webkit-padding-start: 12px;
            padding-inline-start: 12px;
    border-radius: 8px;
    margin-left: 8px;
}
.foot2 {
    align-self: flex-end;
    
}
.footernav {
    margin-top: -24px;
}
.footernav li {
    margin-top: 0px;
    font-size: small;
}
.footerul li img {
    margin-right: 6px;
}
.quick {
    text-align: center;
    margin-left: -80px;
    margin-top: 0px;
    margin-bottom: 0px;
    height:22px;
}
@media (min-width: 40em) {
    .quick {
        height:12px;
    }
}
.copyright {
    margin-top: 0px;
}
@media (max-width: 40em) {
    .copyright {
       background-color: #181d22;
    }
    .quick {
        margin-left: -78px;
    }
}
@media (min-width: 40em) {
    .footernav li{
        display: inline;
        margin-right: 18px;
        
    }
}
.copyright > span {
    color: gray;
}
.footerbutton {
    margin-top: -30px;
}
@media (max-width: 40em) {
    .foot2{
        position: absolute;
        right: 12px;
        
    }
    .footerlogo {
        width: 220px;
        height: 220px;
    }
}
.overlay__containershow {
    display: inline;
    display: initial;
}
.overlay__containerhide {
    display: none;
}
.modal__backdrop {
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    background: rgba(36, 36, 39, 0.559);
    position: fixed;
    top: 0;
    z-index: 20;
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
    display: block;
}
.overlay {
    z-index: 1500;
    border: 3px solid #149fc2;
    border-radius: 12px;
    width: 300px;
    height: 200px;
    position: fixed;
    left: 50%;
    top: 50%; 
    margin-left: -150px;
    margin-top: -150px;
    background-color: hsl(210, 19%, 13%, 1);
}
.contact__header {
    margin-bottom: 0px;
    color: hsl(0, 0%, 99%, 0.6);
    padding: 0px;
    font-weight: 400;
    display: table;
    margin: 0 auto;
    margin-top: 24px;
}
.images {
    /* display: flex; */
    justify-content: space-around;
    margin-top: 0px;
    display: table;
    margin: 0 auto;
}

.contact_img {
    width: 120px;
    height: 120px;
}
