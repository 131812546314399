.aside {
    padding-top: 110px;
}
@media (max-width: 1500px) {
    .aside {
        padding-top: 110px;
    }
}

.aside__top {
    font-size: large;
    height: 48px !important;
    width: 170px !important;
    background-color: #4AAAFF;
    border-radius: 6px;
    border: 0;
    transition: all 1s;
    color: white;
}
.aside__top:hover {
    border: 1px solid #4AAAFF;
    color: #4AAAFF;
    background-color: rgb(26, 32, 38, .99);
    transition: all 1s;
}
.aside .asidebutton {
    margin-top: 40px;
    height: 50px;
    width: 156px;
    font-size: large;
}
.asidelogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

}
@media (min-width: 48em) {
    .aside {
        padding-top: 60px;
    }
    .asidelogo {
        width: 580px;
        height: 200;
    }
    .asidemenubar {
        display: none;
    }
}@media (orientation: portrait) {
    .aside {
        height: auto;
    }
}
.aside ul {
    list-style-type: none;
    margin-left: auto;
    margin-right: auto;
    padding-inline-start: 0;
    margin-top: 40px;
}
.aside ul li {
    display: inline;
    margin-right: 6px;
}
.aside ul:first-child {
    margin-left: -6px;
}

/*  
    for the cards the parent element must have a position
    other than static for the abosolute value to work...
*/
.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4% 5%;
    /* width: 900px; */
}
.card {
    position: relative;
    margin-top: auto;
}

.glowDot {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1a2026;
    box-shadow: inset 0px 0px 10px 2px #4AAAFF,
                    0px 0px 10px 2px #1c74c1;
    animation: pulse 0.6s alternate infinite;
    bottom: 21px;
    right: 14px;
}

@keyframes pulse {
    0% {
      background: #1c74c1;
      box-shadow: none;
    }
    100% {
      background: #1c74c1;
      box-shadow: inset 0px 0px 10px 2px #1c74c1
                        0px 0px 15px 2px #93c9f8;
    }
  }