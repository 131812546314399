/* what CSS stylesheet */
@media (min-width: 45em) {
  .what {
    display: flex;
    justify-items: normal;
    justify-content: space-between;
    margin-bottom: 100px;
  }
  .what__desktop2 {
    margin-left: 360px;
  }
}

.what, h5 {
  text-align: left;
  padding-left: 12px;
  height: 23px;
  width: 140px;
  font-weight: 500;
}
.what, p {
  font-size: 0.8em;
  padding-left: 16px !important;
  padding-right: 12px;
  text-align: left;
  font-weight: 300;
  line-height: 1.4rem;
  font-size: smaller;
  width: auto;
  height: auto;
}
.MNMA {
  color: #1c74c1;
}
.whatcards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px 12px;
}
.whatcard {
  position: relative;
  margin-top: auto;
}

.whatglowDot {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #1a2026;
    box-shadow: inset 0px 0px 10px 2px #4AAAFF,
                    0px 0px 10px 2px #1c74c1;
    animation: pulse 0.6s alternate infinite;
    bottom: 18px;
    right: 8px;
}

@keyframes pulse {
  0% {
    background: #1c74c1;
    box-shadow: none;
  }
  100% {
    background: #1c74c1;
    box-shadow: inset 0px 0px 10px 2px #1c74c1
                      0px 0px 15px 2px #93c9f8;
  }
}

.whatimage {
  position: relative;
  height: 280px;
  margin-top: 3em;
}
.whatimage__telegram {
  position: absolute;
  bottom: 66px;
  right: 88px;
  width: 100px;
  animation: telegram 1s alternate infinite; 
}
.whatimage__discord {
  position: absolute;
  right: 120px;
  width: 160px;
  animation: discord 1s alternate infinite;
}
.whatimage__twitter {
  position: absolute;
  right: 202px;
  top: -40px;
  width: 120px;
  animation: twitter 1s alternate infinite;
}

@keyframes discord {
  0% {
    transform: scale(0.88);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes telegram {
  0% {
    transform: scale(0.88) translate(0px, 0px);
  }
  100% {
    transform: scale(1) translate(10px, 16px);
  }
}
@keyframes twitter {
  0% {
    transform: scale(0.88) translate(10px, 10px);
  }
  100% {
    transform: scale(1) translate(-6px, 0px); 
  }
}


/* About CSS stylesheet */
.about, h5 {
  text-align: left;
  padding-left: 4px;
  font-weight: 500;
}
.about, p {
  width: auto;
}
.aboutimage {
  position: relative;
  top: -24px;
  height: 340px;
  z-index: -12;
  margin-left: 12px;
}

@media (min-width: 45em) {
  .about {
    display: flex;
    justify-items: normal;
    justify-content: space-between;
    margin-bottom: 100px;
    align-items: center;
  }
  .what__desktop1 > h5 {
    margin-top: 120px;
  }
  .what__desktop2 {
    margin-left: 360px;
    margin-top: 90px;
  }
  .about__desktop2 {
    order: -1;
  }
  .abouticon {
    margin-right: 80px;
    transform: scale(1.4)
  }
}

@media (min-width: 56em) {
  .abouticon {
    margin-right: 80px;
    transform: scale(1.7)
  }
}
